import {
    PrepCheckDTO,
    PrepCheckUserSubmission,
    PrepCheckDraftDetails,
    EmergencyItemsDTO,
    PrepCheckStatus,
    LanguageCode,
    PrepCheckResultOverview,
    PrepCheckUserStats,
    PrepCheckResultsDTO,
    PrepCheckUpNextDTO,
    PrepCheckOverviewDTO,
    PrepCheckUserSubmissionDTO,
    shuffle,
} from '@hazadapt-git/public-core-base'
import { AxiosResponse } from 'axios'
import { EmergencyItem, EmergencyItemPrepCheckTooltip } from '../../components'
import { isa } from '../api'

/**
 * Get overviews of all prep checks
 * @param lang The language in which to get the prep check (optional)
 * @returns Promise<PrepCheckOverview[]>
 */
export const getPrepCheckOverviews = async (
    lang: LanguageCode = LanguageCode.ENGLISH
): Promise<PrepCheckOverviewDTO[]> => {
    const response: AxiosResponse<PrepCheckOverviewDTO[]> = await isa.get(
        `/public/prep-checks?lang=${lang}`
    )
    if (response.status !== 200) {
        throw new Error('Unable to get prep checks')
    }
    return response.data
}

/**
 * Get all prep checks for a given hazard
 * @param hazard_id the hazard ID
 * @param lang the language to return prep check overviews in
 * @returns Promise<PrepCheckOverviewDTO[]>
 */
export const getPrepChecksByHazard = async (
    hazard_id: number,
    lang: LanguageCode = LanguageCode.ENGLISH
): Promise<PrepCheckOverviewDTO[]> => {
    const response: AxiosResponse<PrepCheckOverviewDTO[]> = await isa.get(
        `/public/prep-checks-by-hazard/${hazard_id}?lang=${lang}`
    )
    if (response.status !== 200) {
        throw new Error('Unable to get prep checks')
    }
    return response.data
}

/**
 * Get prep check submissions
 * @returns Promise<PrepCheckDraftDetails[]>
 */
export const getAllSubmissions = async (
    lang: LanguageCode = LanguageCode.ENGLISH
): Promise<PrepCheckDraftDetails[]> => {
    const response: AxiosResponse<PrepCheckDraftDetails[]> = await isa.get(
        `/public/prep-check-submissions?lang=${lang}&overview=true`
    )
    if (response.status !== 200) {
        throw new Error('Unable to get prep check drafts')
    }
    return response.data
}

/**
 * Get a draft prep check submission
 * @param prep_check_id The prep check ID
 * @param lang The language to return the submission in
 * @returns Promise<PrepCheckUserSubmission>
 */
export const getOneDraftSubmission = async (
    prep_check_id: number,
    lang: LanguageCode = LanguageCode.ENGLISH
): Promise<PrepCheckUserSubmission> => {
    const response: AxiosResponse<PrepCheckUserSubmission> = await isa.get(
        `/public/prep-check-submissions/${prep_check_id}?lang=${lang}&drafts=true`
    )
    if (response.status !== 200) {
        throw new Error('Unable to get prep check drafts')
    }
    return response.data
}

/**
 * Get prep check results
 * @returns Promise<PrepCheckResults[]>
 */
export const getAllPrepCheckResults = async (): Promise<
    PrepCheckResultOverview[]
> => {
    const response: AxiosResponse<PrepCheckResultOverview[]> = await isa.get(
        'public/prep-check-results'
    )
    if (response.status !== 200) {
        throw new Error('Unable to get prep check results')
    }
    return response.data
}

/**
 * Get results for one prep check (returns the user's best result if they have done it more than once)
 * @param id the prep check ID
 * @param lang the language to return the results in
 * @returns Promise<PrepCheckResults>
 */
export const getOnePrepCheckResult = async (
    id: number,
    lang: LanguageCode = LanguageCode.ENGLISH
): Promise<PrepCheckResultsDTO> => {
    const response: AxiosResponse<PrepCheckResultsDTO> = await isa.get(
        `public/prep-check-results/${id}?overview=false&lang=${lang}`
    )
    if (response.status !== 200) {
        throw new Error('Unable to get prep check results')
    }
    return response.data
}

/**
 * Get result overview for one prep check
 * @param id the prep check ID
 * @returns Promise<PrepCheckResults>
 */
export const getOnePrepCheckResultOverview = async (
    id: number,
    lang: LanguageCode = LanguageCode.ENGLISH
): Promise<PrepCheckResultOverview> => {
    const response: AxiosResponse<PrepCheckResultOverview> = await isa.get(
        `public/prep-check-results/${id}?overview=true&lang=${lang}`
    )
    if (response.status !== 200) {
        throw new Error('Unable to get prep check results')
    }
    return response.data
}

/**
 * Get the prep check with the given ID
 * @param id the unique ID of the prep check
 * @param lang the language to get the prep check data in
 * @returns Promise<PrepCheck>
 */
export const getPrepCheck = async (
    id: number,
    lang: LanguageCode = LanguageCode.ENGLISH
): Promise<PrepCheckDTO> => {
    const response: AxiosResponse<PrepCheckDTO> = await isa.get(
        `/public/prep-checks/${id}?lang=${lang}`
    )
    if (response.status !== 200) {
        throw new Error('Unable to get prep check')
    }
    const prepCheck = response.data
    for (const q of prepCheck.questions) {
        if (
            q.question_type === 'OneD_Ordered_List' ||
            q.question_type === 'TwoD_Grid_Placement'
        ) {
            shuffle(q.options)
        }
    }
    return response.data
}

/**
 * Get the up-next prep check
 * @param prep_check_id the prep check ID to reference
 * @param lang the language to get the prep check data in
 * @returns Promise<PrepCheckUpNextDTO>
 */
export const getUpNextPrepCheck = async (
    prep_check_id: number,
    lang: LanguageCode = LanguageCode.ENGLISH
): Promise<PrepCheckUpNextDTO> => {
    const response: AxiosResponse<PrepCheckUpNextDTO> = await isa.get(
        `/public/prep-check-up-next/${prep_check_id}?lang=${lang}`
    )
    if (response.status !== 200) {
        throw new Error('Unable to get up-next prep check')
    }
    return response.data
}

/**
 * Get emergency items
 * @returns Promise<EmergencyItemsDTO>
 */
export const getEmergencyItems = async (
    lang: LanguageCode = LanguageCode.ENGLISH
): Promise<EmergencyItemsDTO> => {
    const response: AxiosResponse<EmergencyItemsDTO> = await isa.get(
        `/public/web/emergency-items?lang=${lang}`
    )
    if (response.status !== 200) {
        throw new Error('Unable to get emergency items')
    }
    return response.data
}

/**
 * Get the emergency items the user already has for this prep check
 * @param id the prep check ID
 * @returns Promise<number[]>
 */
export const getEmergencyItemsForPrepCheck = async (
    id: number
): Promise<number[]> => {
    const response: AxiosResponse<number[]> = await isa.get(
        `/public/emergency-items/${id}`
    )
    if (response.status !== 200) {
        throw new Error('Unable to get emergency items')
    }
    return response.data
}

/**
 * Toggle the status of an emergency item (i.e. whether or not the user has it)
 * @param id The item ID to update
 */
export const toggleEmergencyItemStatus = async (
    item_name: string,
    hasItem: boolean
): Promise<EmergencyItemsDTO> => {
    const response: AxiosResponse<EmergencyItemsDTO> = await isa.put(
        `/public/web/emergency-items`,
        {
            item_name,
            hasItem,
        }
    )
    if (response.status !== 200) {
        throw new Error('Unable to toggle status of emergency item')
    }
    return response.data
}

/**
 * Save a prep check submission draft
 * @param submission the prep check submission
 * @param newSubmission a flag of whether or not it's a new or existing submission
 */
export const savePrepCheckSubmission = async (
    submission: PrepCheckUserSubmissionDTO
): Promise<void> => {
    let response: AxiosResponse<void>
    if (!submission.id) {
        response = await isa.post(`/public/prep-check-submissions`, submission)
    } else {
        response = await isa.put(`/public/prep-check-submissions`, submission)
    }
    if (response.status !== 201 && response.status !== 200) {
        throw new Error(
            `Unable to save prep check ${
                submission.status === PrepCheckStatus.SUBMITTED
                    ? 'submission.'
                    : 'submission update.'
            }`
        )
    }
}

/**
 * Get the user's prep check stats (journey phase and number of prep checks completed)
 * @returns Promise<PrepCheckUserStats>
 */
export const getPrepCheckUserStats = async (
    lang: LanguageCode = LanguageCode.ENGLISH
): Promise<PrepCheckUserStats> => {
    const response: AxiosResponse<PrepCheckUserStats> = await isa.get(
        `/public/prep-check-stats?lang=${lang}`
    )

    if (response.status !== 200) {
        throw new Error('Unable to get prep check stats')
    }
    return response.data
}

export interface EmergencyItemListConfig {
    yes: EmergencyItem[]
    no: EmergencyItem[]
}
export const buildEmergencyItemLists = (
    items: EmergencyItemsDTO,
    prep_checks: PrepCheckOverviewDTO[]
): EmergencyItemListConfig => {
    const userHas: EmergencyItem[] = []
    const userDoesNotHave: EmergencyItem[] = []

    // Build list of "yes" items
    for (const item of items.yes) {
        const relatedPrepChecks: EmergencyItemPrepCheckTooltip[] = []
        for (const pc of item.prep_checks) {
            const prep_check = prep_checks.find((p) => p.id === pc)
            if (prep_check) {
                relatedPrepChecks.push({
                    id: prep_check.id,
                    name: prep_check.title,
                    icon: prep_check.dark_icon?.url || '', // WIP
                    alt: prep_check.dark_icon?.alt,
                })
            }
        }
        userHas.push({
            ...item,
            userHasItem: true,
            prep_checks: relatedPrepChecks,
        })
    }

    // Build list of "no" items
    for (const item of items.no) {
        const relatedPrepChecks: EmergencyItemPrepCheckTooltip[] = []
        for (const pc of item.prep_checks) {
            const prep_check = prep_checks.find((p) => p.id === pc)
            if (prep_check) {
                relatedPrepChecks.push({
                    id: prep_check.id,
                    name: prep_check.title,
                    icon: prep_check.dark_icon?.url || '', // WIP
                    alt: prep_check.dark_icon?.alt,
                })
            }
        }
        userDoesNotHave.push({
            ...item,
            userHasItem: false,
            prep_checks: relatedPrepChecks,
        })
    }

    return {
        yes: userHas,
        no: userDoesNotHave,
    }
}

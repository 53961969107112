import {
    ErrorMessage,
    LanguageCode,
    User,
} from '@hazadapt-git/public-core-base'
import {
    ActionReducerMapBuilder,
    createAsyncThunk,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit'

import { readAsync, storeAsync } from '../async-storage'
import {
    addProfilePicture,
    deleteProfilePicture,
    getProfile,
    getProfilePicture,
    switchLanguage,
    updateProfile,
} from '../utils'

export interface ProfileStoreState {
    user?: User | null
    profilePictureURI: string | undefined
    language: LanguageCode
    error: string | null | undefined
    profileReady: boolean
}

export interface SwitchLanguageDTO {
    lang: LanguageCode
    newLang: boolean
}

const INITIAL_PROFILE_STORE_STATE: ProfileStoreState = {
    profilePictureURI: undefined,
    error: null,
    language: LanguageCode.ENGLISH,
    profileReady: false,
}

export const getProfileThunk = createAsyncThunk<
    User,
    void,
    { rejectValue: ErrorMessage | string }
>('profile/get', async (_, { rejectWithValue }) => {
    try {
        return await getProfile()
    } catch (err: any) {
        return rejectWithValue((err as Error).message)
    }
})

export const getLanguageThunk = createAsyncThunk<
    LanguageCode,
    void,
    { rejectValue: ErrorMessage | string }
>('language/get', async (_, { getState, rejectWithValue }) => {
    const {
        profile: { user },
    } = getState() as {
        profile: ProfileStoreState
    }
    if (user) return user.preferred_language
    const language = readAsync('@language')
    if (!language) {
        try {
            storeAsync('@language', LanguageCode.ENGLISH.toString())
            return LanguageCode.ENGLISH
        } catch (err) {
            return rejectWithValue((err as DOMException).message)
        }
    } else {
        return language as unknown as LanguageCode
    }
})

export const switchLanguageThunk = createAsyncThunk<
    LanguageCode,
    LanguageCode,
    { rejectValue: ErrorMessage | string }
>(
    'language/switch',
    async (lang: LanguageCode, { rejectWithValue, getState, dispatch }) => {
        try {
            const {
                profile: { user },
            } = getState() as {
                profile: ProfileStoreState
            }
            if (user) {
                await dispatch(
                    saveProfileChangesThunk({ preferred_language: lang })
                )
            } else {
                switchLanguage(lang)
            }
            return lang
        } catch (err) {
            return rejectWithValue((err as DOMException).message)
        }
    }
)

export const saveProfileChangesThunk = createAsyncThunk<
    User,
    Partial<User>,
    { rejectValue: ErrorMessage | string }
>(
    'profile/update',
    async (updatedUser: Partial<User>, { getState, rejectWithValue }) => {
        try {
            const state = getState() as any
            const originalUser = state.profile.user
            const user: User = { ...originalUser, ...updatedUser }
            await updateProfile(user)
            return user
        } catch (err: any) {
            return rejectWithValue(err)
        }
    }
)

export const addProfilePictureThunk = createAsyncThunk<
    string,
    File,
    { rejectValue: ErrorMessage | string }
>('profile-picture/add', async (image, { rejectWithValue }) => {
    try {
        return await addProfilePicture(image)
    } catch (err: any) {
        return rejectWithValue(err)
    }
})

export const getProfilePictureThunk = createAsyncThunk<
    string,
    void,
    { rejectValue: ErrorMessage | string }
>('profile-picture/get', async (_, { rejectWithValue }) => {
    try {
        return await getProfilePicture()
    } catch (err: any) {
        return rejectWithValue(err)
    }
})

export const deleteProfilePictureThunk = createAsyncThunk<
    void,
    void,
    { rejectValue: ErrorMessage | string }
>('profile-picture/delete', async (_, { rejectWithValue }) => {
    try {
        await deleteProfilePicture()
    } catch (err: any) {
        return rejectWithValue(err)
    }
})

const profileSlice = createSlice({
    name: 'profile',
    initialState: INITIAL_PROFILE_STORE_STATE,
    reducers: {
        setProfileReady: (state) => {
            state.profileReady = true
        },
        updateProfileLocally: (state, action: PayloadAction<Partial<User>>) => {
            if (state.user) {
                state.user = {
                    ...state.user,
                    ...action.payload,
                }
            }
        },
    },
    extraReducers: (builder: ActionReducerMapBuilder<ProfileStoreState>) => {
        builder.addCase(getProfileThunk.fulfilled, (state, action) => {
            state.user = action.payload
            state.error = null
        })
        builder.addCase(getProfileThunk.rejected, (state, action) => {
            state.user = null
            state.error = action.payload
        })

        builder.addCase(getLanguageThunk.fulfilled, (state, action) => {
            state.language = action.payload
        })

        builder.addCase(getLanguageThunk.rejected, (state, action) => {
            state.error = action.payload
            state.language = LanguageCode.ENGLISH
        })

        builder.addCase(switchLanguageThunk.fulfilled, (state, action) => {
            state.language = action.payload
            state.error = null
        })

        builder.addCase(addProfilePictureThunk.fulfilled, (state, action) => {
            state.profilePictureURI = action.payload
            state.error = null
        })
        builder.addCase(addProfilePictureThunk.rejected, (state, action) => {
            state.error = action.payload
        })

        builder.addCase(getProfilePictureThunk.fulfilled, (state, action) => {
            state.profilePictureURI = action.payload
            state.error = null
        })
        builder.addCase(getProfilePictureThunk.rejected, (state, action) => {
            state.error = action.payload
        })

        builder.addCase(
            deleteProfilePictureThunk.fulfilled,
            (state, _action) => {
                state.profilePictureURI = undefined
                state.error = null
            }
        )
        builder.addCase(deleteProfilePictureThunk.rejected, (state, action) => {
            state.error = action.payload
        })
        builder.addCase(saveProfileChangesThunk.fulfilled, (state, action) => {
            state.user = action.payload
        })
        builder.addCase(saveProfileChangesThunk.rejected, (state, action) => {
            state.error = action.payload
        })
    },
})

export const { updateProfileLocally, setProfileReady } = profileSlice.actions

export default profileSlice.reducer

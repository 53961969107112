import {
    CSOScope,
    PrepCheckAnswerOptionDTO,
    PrepCheckScope,
    SupportedContentBooster,
    SupportedHazardStage,
    colors,
} from '@hazadapt-git/public-core-base'
import { CloudCircle, MoreVert } from '@mui/icons-material'
import { Grid, Typography, Divider, IconButton } from '@mui/material'
import {
    PrepCheckComponentRating,
    PrepCheckStatus,
} from '@hazadapt-git/public-core-base'
import React, { FC, useState } from 'react'

import Breathing from '../../assets/icons/Breathing.svg'
import PDF from '../../assets/icons/PDF.svg'
import PrepareGray from '../../assets/icons/Prepare-Gray.svg'
import PrepareRed from '../../assets/icons/Prepare-Red.svg'
import ReactGray from '../../assets/icons/React-Gray.svg'
import ReactRed from '../../assets/icons/React-Red.svg'
import RecoverGray from '../../assets/icons/Recover-Gray.svg'
import RecoverRed from '../../assets/icons/Recover-Red.svg'
import Overcome from '../../assets/images/Overcome.svg'
import PhaseStar from '../../assets/icons/PhaseStar.svg'

import { customDarkBlueColor } from '../../lib/styles/universal'
import logo from '../../logo.svg'
import { Card } from '../atoms'
import {
    CSOItem,
    ChipGrid,
    ContentBlockDetails,
    ContentBlockPreview,
    EmptyContentPlaceholder,
    HazardCard,
    HazardCategory,
    HazardStage,
    FAQBlock,
    LocalContentSwitch,
    LocalContentBlockAddition,
    OptionsView,
    Panel,
    ProfileLinkWithAvatar,
    ScrollingBlock,
    TextWithIcon,
    WhatToKnow911Details,
    WhenToCall911Item,
    CSOFilterPickerSection,
    CSOFilterPickerButton,
    PrepCheckProgressBar,
    PrepCheckBadgeDetails,
    PrepCheckCard,
    PrepCheckCardDropItem,
    PrepCheckResultDetails,
    PrepCheckResultPreviewItem,
    PrepCheckDataPill,
    PrepCheckQuestionHeading,
    PrepCheckAnswerCard,
    PrepCheckQuestionListItem,
    PrepCheckScoreGraph,
    PrepCheckContentBlock,
    LoadingView,
} from '../molecules'
import { CSOFilterPicker } from '../organisms'
import { ContentBooster, HazardFilter } from '../../lib/entities'

interface MoleculesProps {}

export const Molecules: FC<MoleculesProps> = (props: MoleculesProps) => {
    const [activeCategories, setActiveCategories] = useState<
        ('health' | 'crime' | 'common')[]
    >(['health'])
    const [activeStage, setActiveStage] =
        useState<SupportedHazardStage>('Before')
    const [selected, setSelected] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
    const optionsViewOpen = Boolean(anchorEl)

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [prepCheckStage, setPrepCheckStage] = useState<PrepCheckStatus>()
    const [locoOn, setLocoOn] = useState<boolean>(false)

    const [activeChips, setActiveChips] = useState<SupportedContentBooster[]>(
        []
    )
    const onChipPress = (name: SupportedContentBooster) => {
        const active = [...activeChips]
        const index = active.findIndex((c) => c === name)
        if (index === -1) active.push(name)
        else active.splice(index, 1)
        setActiveChips(active)
    }

    const onResetPress = () => {
        setActiveChips([])
    }

    const [csoFilterPickerOpen, setCSOFilterPickerOpen] = React.useState(false)
    const [currentPrepCheckQuestion, setCurrentPrepCheckQuestion] =
        React.useState<number>(3)
    const [pcResultPreviewOpen, setPCResultPreviewOpen] = React.useState(false)

    const handleClose = () => {
        setCSOFilterPickerOpen(false)
    }

    const handleOpen = () => {
        setCSOFilterPickerOpen(true)
    }

    const sampleAnswerOption: PrepCheckAnswerOptionDTO = {
        id: 1,
        weight: 1,
        text: 'Make and evactuation plan with my household',
        explainer_text: 'Loris Ipsum...',
        image: { src: logo, alt: '' },
    }

    return (
        <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            padding="1rem"
        >
            <Grid
                item
                xs={12}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    ChipGrid
                </Typography>
                <ChipGrid
                    chips={Object.keys(ContentBooster).map(
                        (cb) => ContentBooster[cb]
                    )}
                    activeChips={activeChips}
                    availableChips={activeChips}
                    guidingText="Show for:"
                    onChipPress={onChipPress}
                    onResetPress={() => setActiveChips([])}
                    showDeleteIcon
                />
                <Divider />
            </Grid>
            <Grid
                item
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    ContentBlockDetails
                </Typography>
                <ContentBlockDetails
                    id={1}
                    blockIcon={logo}
                    blockIconAlt="React logo"
                    title="Content Block Details"
                    body={`* A demo of \`react-markdown\`
* \`react-markdown\` is a markdown component for React.
* 👉 Changes are re-rendered as you type.
* 👈 Try writing some markdown on the left.`}
                    localContent={[
                        {
                            creator: {
                                id: 1,
                                name: 'Oregon State University',
                                logo: {
                                    url: 'https://communications.oregonstate.edu/sites/communications.oregonstate.edu/files/osu-primarylogo-2-compressor.jpg',
                                    src: ' ',
                                    alt: 'OSU',
                                },
                            },
                            body: `A demo of \`react-markdown\`\n
\`react-markdown\` is a markdown component for React.
* 👉 Changes are re-rendered as you type.
* 👈 Try writing some markdown on the left.`,
                        },
                    ]}
                    importantSources={[
                        {
                            name: 'HazAdapt Website',
                            link: 'https://hazadapt.com',
                        },
                    ]}
                    onLinkPress={() => {}}
                    type="content"
                    local={true}
                    onLocalContentAdditionHover={() => {}}
                    onLocalContentAdditionLinkClick={() => {}}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                lg={4}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    ContentBlockPreview
                </Typography>
                <ContentBlockPreview
                    id={1}
                    icon={logo}
                    iconAlt="React logo"
                    title="Content Block"
                    selected={selected}
                    onPress={() => setSelected(!selected)}
                    boosterChips={[
                        ContentBooster['Kids'],
                        ContentBooster['Pets'],
                        ContentBooster['Farm_Animals'],
                    ]}
                    type="content"
                    creator={{
                        id: 1,
                        name: 'Oregon State University',
                        logo: {
                            url: 'https://communications.oregonstate.edu/sites/communications.oregonstate.edu/files/osu-primarylogo-2-compressor.jpg',
                            src: ' ',
                            alt: 'OSU',
                        },
                    }}
                    local={true}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    CSOFilterPickerButton
                </Typography>
                <CSOFilterPickerButton
                    value={activeChips}
                    open={csoFilterPickerOpen}
                    handleOpen={handleOpen}
                    handleClose={handleClose}
                    csoFilterSection={
                        <CSOFilterPicker
                            activeChips={activeChips}
                            availableChips={Object.values(ContentBooster).map(
                                (cb) => cb.key
                            )}
                            onChipPress={onChipPress}
                            onResetPress={onResetPress}
                            onCancelPress={handleClose}
                        />
                    }
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    CSOFilterPickerSection
                </Typography>
                <div>
                    <CSOFilterPickerSection
                        chips={Object.values(ContentBooster).filter(
                            (cb) => cb.type === 'Humans'
                        )}
                        activeChips={activeChips}
                        availableChips={Object.values(ContentBooster)
                            .filter((cb) => cb.type === 'Humans')
                            .map((cb) => cb.key)}
                        title="Filter By Humans"
                        onChipPress={onChipPress}
                    />
                    <Divider
                        sx={{ width: '100%', marginLeft: 0, marginRight: 0 }}
                    />
                    <CSOFilterPickerSection
                        chips={Object.values(ContentBooster).filter(
                            (cb) => cb.type === 'Animals'
                        )}
                        activeChips={activeChips}
                        availableChips={Object.values(ContentBooster)
                            .filter((cb) => cb.type === 'Animals')
                            .map((cb) => cb.key)}
                        title="Filter By Animals"
                        onChipPress={onChipPress}
                    />
                </div>
                <Divider />
            </Grid>
            <Grid
                item
                // xs={12}
                // md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    CSOItem
                </Typography>
                <CSOItem
                    filterChips={[ContentBooster['Farm_Animals']]}
                    config={{
                        id: 1,
                        title: 'Overcome',
                        priority: 1,
                        subheader: 'Subheader Text',
                        phone: '(555) 123-4567',
                        is_text_line: false,
                        link: 'https://google.com',
                        hours: null,
                        always_available: true,
                        who_they_help: 'Kids, Pets',
                        examples: `* A demo of \`react-markdown\`
* \`react-markdown\` is a markdown component for React.
* 👉 Changes are re-rendered as you type.
* 👈 Try writing some markdown on the left.`,
                        details: null,
                        filters: ['Kids', 'Pets'],
                        icon: {
                            src: Overcome,
                            alt: '',
                        },
                        hazards: [21],
                        scope: CSOScope.LOCAL,
                        local: false,
                    }}
                    onClick={() => {}}
                    onWhatTheyHelpWithClick={() => {}}
                    onWhoTheyHelpClick={() => {}}
                    onAboutThisServiceClick={() => {}}
                    onFindNearMeClick={() => {}}
                    onViewWebsiteClick={() => {}}
                    onHover={() => {}}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                lg={4}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    EmptyContentPlaceholder
                </Typography>
                <EmptyContentPlaceholder
                    imageProps={{
                        src: logo,
                    }}
                    text="You don't have anything bookmarked!"
                    buttonProps={{
                        text: 'Press me!',
                        startIcon: <CloudCircle />,
                    }}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                lg={4}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    HazardCard
                </Typography>
                <HazardCard
                    id={1}
                    icon={logo}
                    name="Hazard"
                    description="Example hazard for the showcase."
                    onCopyLinkPress={() => {}}
                    onBookmark={() => alert('bookmark')}
                    onOptionsPress={() => {}}
                    onPrepCheckPress={() => {}}
                    onPress={() => alert('open hazard')}
                    filterChips={[
                        { ...HazardFilter['Transportation'] },
                        { ...HazardFilter['Weather'] },
                    ]}
                    onPdfPress={() => alert('pdf')}
                    local={true}
                    creator={{
                        id: 1,
                        name: 'Oregon State University',
                        logo: {
                            url: 'https://communications.oregonstate.edu/sites/communications.oregonstate.edu/files/osu-primarylogo-2-compressor.jpg',
                            src: ' ',
                            alt: 'OSU',
                        },
                    }}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                lg={4}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    HazardCategory
                </Typography>
                <div style={{ display: 'flex' }}>
                    <HazardCategory
                        active={activeCategories.includes('health')}
                        name="Health"
                        onPress={() => {
                            const activeCats = [...activeCategories]
                            const index = activeCats.findIndex(
                                (c) => c === 'health'
                            )
                            if (index !== -1) {
                                activeCats.splice(index, 1)
                            } else {
                                activeCats.push('health')
                            }
                            setActiveCategories(activeCats)
                        }}
                    />
                    <HazardCategory
                        active={activeCategories.includes('crime')}
                        name="Crime"
                        onPress={() => {
                            const activeCats = [...activeCategories]
                            const index = activeCats.findIndex(
                                (c) => c === 'crime'
                            )
                            if (index !== -1) {
                                activeCats.splice(index, 1)
                            } else {
                                activeCats.push('crime')
                            }
                            setActiveCategories(activeCats)
                        }}
                    />
                    <HazardCategory
                        active={activeCategories.includes('common')}
                        name="Common Incidents"
                        onPress={() => {
                            const activeCats = [...activeCategories]
                            const index = activeCats.findIndex(
                                (c) => c === 'common'
                            )
                            if (index !== -1) {
                                activeCats.splice(index, 1)
                            } else {
                                activeCats.push('common')
                            }
                            setActiveCategories(activeCats)
                        }}
                    />
                </div>
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                lg={4}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    HazardStage
                </Typography>
                <div style={{ display: 'flex' }}>
                    <HazardStage
                        active={activeStage === 'Before'}
                        name="Prepare"
                        onPress={() => setActiveStage('Before')}
                        icon={
                            <img
                                src={
                                    activeStage === 'Before'
                                        ? PrepareRed
                                        : PrepareGray
                                }
                                alt="Prepare icon"
                            />
                        }
                        hasLocalContent={false}
                    />
                    <HazardStage
                        active={activeStage === 'During'}
                        name="React"
                        onPress={() => setActiveStage('During')}
                        icon={
                            <img
                                src={
                                    activeStage === 'During'
                                        ? ReactRed
                                        : ReactGray
                                }
                                alt="React icon"
                            />
                        }
                        hasLocalContent={false}
                    />
                    <HazardStage
                        active={activeStage === 'After'}
                        name="Recover"
                        onPress={() => setActiveStage('After')}
                        icon={
                            <img
                                src={
                                    activeStage === 'After'
                                        ? RecoverRed
                                        : RecoverGray
                                }
                                alt="Recover icon"
                            />
                        }
                        hasLocalContent={false}
                    />
                </div>
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                lg={4}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    FAQBlock
                </Typography>
                <FAQBlock
                    id={1}
                    question="How can I help?"
                    answer={`A demo of \`react-markdown\`\n
\`react-markdown\` is a markdown component for React.
* 👉 Changes are re-rendered as you type.
* 👈 Try writing some markdown on the left.`}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                lg={4}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    LoadingView
                </Typography>
                <LoadingView />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                lg={4}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    LocalContentSwitch
                </Typography>
                <LocalContentSwitch
                    enabled={locoOn}
                    onLocalContentToggle={() => setLocoOn(!locoOn)}
                    onClosePopover={() => {}}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                lg={4}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    LocalContentBlockAddition
                </Typography>
                <LocalContentBlockAddition
                    onLinkPress={() => {}}
                    onHover={() => {}}
                    localContent={{
                        creator: {
                            id: 1,
                            name: 'Oregon State University',
                            logo: {
                                url: 'https://communications.oregonstate.edu/sites/communications.oregonstate.edu/files/osu-primarylogo-2-compressor.jpg',
                                src: ' ',
                                alt: 'OSU',
                            },
                        },
                        body: `A demo of \`react-markdown\`\n
                        \`react-markdown\` is a markdown component for React.
                        * 👉 Changes are re-rendered as you type.
                        * 👈 Try writing some markdown on the left.`,
                    }}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                lg={4}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    OptionsView
                </Typography>
                <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
                    <MoreVert />
                </IconButton>
                <OptionsView
                    anchorEl={anchorEl}
                    open={optionsViewOpen}
                    onClose={() => setAnchorEl(null)}
                    items={[
                        {
                            key: 'optionsItem_ViewPDF',
                            children: (
                                <TextWithIcon
                                    icon={<img src={PDF} alt="View PDF icon" />}
                                    iconLocation="right"
                                    spaceBetween
                                >
                                    View PDF
                                </TextWithIcon>
                            ),
                        },
                    ]}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                lg={4}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    Panel
                </Typography>
                <Panel
                    imageSrc={logo}
                    backgroundColor={customDarkBlueColor}
                    title="Example Panel"
                    subtitle="Lorem ipsum stuff..."
                    textColor={colors.grays.BLANC}
                    onPress={() => {}}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                lg={4}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    PrepCheckAnswerCard
                </Typography>
                <PrepCheckAnswerCard
                    answerText="Family or Friend"
                    answerInfo="Lorem Ipsum stuff..."
                    id={1}
                    questionType="OneD_Ordered_List"
                    image={logo}
                    onClick={() => {}}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                lg={4}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    PrepCheckBadgeDetails
                </Typography>
                <PrepCheckBadgeDetails
                    badgeSrc={logo}
                    badgeName="Intermediate"
                    badgeDescription="Badge description goes here"
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                lg={4}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    PrepCheckCard
                </Typography>
                <PrepCheckCard
                    id={1}
                    status={prepCheckStage}
                    dark_icon={logo}
                    title="Blizzard"
                    score={0.67}
                    badge={
                        prepCheckStage === PrepCheckStatus.SUBMITTED
                            ? {
                                  src: logo,
                                  alt: 'Silver Badge Icon',
                              }
                            : undefined
                    }
                    progressBar={0.25}
                    filterChips={[{ ...HazardFilter['Weather'] }]}
                    onClick={() => {}}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                lg={4}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    PrepCheckCardDropItem
                </Typography>
                <div style={{ display: 'flex', gap: '1rem' }}>
                    <PrepCheckCardDropItem
                        index={1}
                        active={false}
                        questionType="OneD_Ordered_List"
                        priority={1}
                        prepStage={'Before'}
                        answerOption={sampleAnswerOption}
                        onClick={() => {}}
                    />
                    <PrepCheckCardDropItem
                        index={1}
                        active={false}
                        questionType="TwoD_Grid_Placement"
                        priority={1}
                        prepStage={'Before'}
                        answerOption={sampleAnswerOption}
                        onClick={() => {}}
                    />
                </div>
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                lg={4}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    PrepCheckContentBlock
                </Typography>
                <PrepCheckContentBlock
                    id={1}
                    title="Test Prep Check"
                    scope={PrepCheckScope.INDIVIDUAL}
                    hazard_id={1}
                    filters={[]}
                    onBlockClick={() => {}}
                    onShare={() => {}}
                    light_icon={{
                        src: '',
                        alt: '',
                        url: 'https://communications.oregonstate.edu/sites/communications.oregonstate.edu/files/osu-primarylogo-2-compressor.jpg',
                    }}
                    dark_icon={{
                        src: '',
                        alt: '',
                        url: 'https://communications.oregonstate.edu/sites/communications.oregonstate.edu/files/osu-primarylogo-2-compressor.jpg',
                    }}
                    date_updated={new Date()}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                lg={4}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    PrepCheckDataPill
                </Typography>
                <PrepCheckDataPill
                    icon={<img src={PhaseStar} alt="Journey phase star icon" />}
                    label="Scout II"
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                lg={4}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    PrepCheckProgressBar
                </Typography>
                <PrepCheckProgressBar
                    currentQuestion={currentPrepCheckQuestion}
                    questionCount={25}
                    onBackPress={() =>
                        setCurrentPrepCheckQuestion(
                            currentPrepCheckQuestion - 1
                        )
                    }
                    onForwardPress={() =>
                        setCurrentPrepCheckQuestion(
                            currentPrepCheckQuestion + 1
                        )
                    }
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    PrepCheckQuestionHeading
                </Typography>
                <PrepCheckQuestionHeading
                    icon={{ src: logo, alt: '' }}
                    prepCheckQuestionText="Do you know what to do in the event of a wildfire?"
                    prepCheckQuestionInstructions="Place the actions below in order or before, during and after the wildfire."
                    prepCheckQuestionType="TwoD_Grid_Placement"
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                lg={4}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    PrepCheckQuestionListItem
                </Typography>
                <PrepCheckQuestionListItem src={logo} text="911" />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                lg={4}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    PrepCheckResultDetails
                </Typography>
                <PrepCheckResultDetails
                    questionName="Wildfire Emergency Contacts"
                    componentRating={PrepCheckComponentRating.MEDIUM}
                    details={`A demo of \`react-markdown\`\n
\`react-markdown\` is a markdown component for React.
* 👉 Changes are re-rendered as you type.
* 👈 Try writing some markdown on the left.`}
                    questionIcon={{
                        src: logo,
                        alt: '',
                    }}
                    dateLastUpdated={new Date('September 26, 2022')}
                    onLinkPress={() => {}}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                lg={4}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    PrepCheckResultPreviewItem
                </Typography>
                <PrepCheckResultPreviewItem
                    questionId={1}
                    questionName="Wildfire Emergency Items"
                    componentRating={PrepCheckComponentRating.LOW}
                    breakdownText={`<strong><font color="red">3 Importing Missing Items!</font></strong>\n
* 👉 Changes are re-rendered as you type.
* 👈 Try writing some markdown on the left.`}
                    questionIcon={{
                        src: logo,
                        alt: '',
                    }}
                    questionText="2. What safety supplies do you have around you that could help you in the event of a wildfire?"
                    open={pcResultPreviewOpen}
                    onToggle={() =>
                        setPCResultPreviewOpen(!pcResultPreviewOpen)
                    }
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                lg={4}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    PrepCheckScoreGraph
                </Typography>
                <PrepCheckScoreGraph
                    score={0.67}
                    label="Intermediate Preparedness"
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                lg={4}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    ProfileLinkWithAvatar
                </Typography>
                <Card colors={[customDarkBlueColor]}>
                    <ProfileLinkWithAvatar
                        name="Omeed"
                        options={[
                            {
                                children: 'Option 1',
                            },
                            {
                                children: 'Option 2',
                            },
                        ]}
                    />
                </Card>
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                lg={4}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    ScrollingBlock
                </Typography>
                <ScrollingBlock
                    imageSrc={logo}
                    name="React Block"
                    onPress={() => {}}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                lg={4}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="h3" mb="1rem">
                    TextWithIcon
                </Typography>
                <TextWithIcon icon={logo}>Text with icon.</TextWithIcon>
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                lg={4}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h3" mb="1rem">
                    WhatToKnow911Details
                </Typography>
                <WhatToKnow911Details
                    id={1}
                    title="What to know title:"
                    content={`* A demo of \`react-markdown\`
* \`react-markdown\` is a markdown component for React.
* 👉 Changes are re-rendered as you type.
* 👈 Try writing some markdown on the left.`}
                    images={[{ src: logo }, { src: logo }, { src: logo }]}
                />
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{ backgroundColor: customDarkBlueColor }}
            >
                <Typography variant="h3" mb="1rem" color={colors.grays.BLANC}>
                    WhenToCall911Item
                </Typography>
                <WhenToCall911Item
                    image={{
                        src: Breathing,
                        alt: 'Breathing icon',
                    }}
                    text="If you're having trouble breathing..."
                />
                <Divider />
            </Grid>
        </Grid>
    )
}

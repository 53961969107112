import { BookmarkOverview, ErrorMessage } from '@hazadapt-git/public-core-base'
import {
    ActionReducerMapBuilder,
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit'

import { removeBookmark, getBookmarks, addBookmark } from '../utils'

interface BookmarkState {
    bookmarkedHazards: BookmarkOverview[]
    error?: string | null
}

export const INITIAL_BOOKMARK_STORE_STATE: BookmarkState = {
    bookmarkedHazards: [],
    error: '',
}

export const getBookmarksThunk = createAsyncThunk<
    BookmarkOverview[],
    void,
    { rejectValue: ErrorMessage | string }
>('bookmarks/get', async (_, { rejectWithValue }) => {
    try {
        return await getBookmarks()
    } catch (err: any) {
        return rejectWithValue(err)
    }
})

export const addBookmarkThunk = createAsyncThunk<
    BookmarkOverview[],
    number,
    { rejectValue: ErrorMessage }
>('bookmarks/add', async (hazard_id: number, { rejectWithValue }) => {
    try {
        await addBookmark(hazard_id)
        return await getBookmarks()
    } catch (err: any) {
        return rejectWithValue(err)
    }
})

export const removeBookmarkThunk = createAsyncThunk<
    BookmarkOverview[],
    number,
    { rejectValue: ErrorMessage }
>('bookmarks/delete', async (bookmark_id: number, { rejectWithValue }) => {
    try {
        await removeBookmark(bookmark_id)
        return await getBookmarks()
    } catch (err: any) {
        return rejectWithValue(err)
    }
})

const BookmarkSlice = createSlice({
    name: 'bookmarks',
    initialState: INITIAL_BOOKMARK_STORE_STATE,
    reducers: {},
    extraReducers: (builder: ActionReducerMapBuilder<BookmarkState>) => {
        builder.addCase(addBookmarkThunk.fulfilled, (state, action) => {
            state.bookmarkedHazards = action.payload
            state.error = null
        })
        builder.addCase(addBookmarkThunk.rejected, (state, action) => {
            state.error = action.payload
        })
        builder.addCase(getBookmarksThunk.fulfilled, (state, action) => {
            state.bookmarkedHazards = action.payload
            state.error = null
        })
        builder.addCase(getBookmarksThunk.rejected, (state, action) => {
            state.error = action.payload
        })
        builder.addCase(removeBookmarkThunk.fulfilled, (state, action) => {
            state.bookmarkedHazards = action.payload
            state.error = null
        })
        builder.addCase(removeBookmarkThunk.rejected, (state, action) => {
            state.error = action.payload
        })
    },
})

export default BookmarkSlice.reducer

import { CommunitySponsor, colors } from '@hazadapt-git/public-core-base'
import { Grid, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import classNames from 'classnames'
import React, { MouseEventHandler } from 'react'

import CommunitySponsorIcon from '../../assets/icons/CommunitySponsor.svg'
import { useStyles } from '../../lib/styles/universal'
import { Card, HelpIcon } from '../atoms'
import { CommunitySponsorView } from '../molecules'

interface CommunitySponsorBlockProps {
    sponsors: CommunitySponsor[]
    onLinkPress(id: number, url: string): void
    onSponsorAreaHover?(sponsor_id: number): void
    onSponsorAreaClick?(sponsor_id: number): void
    onSponsorSupplementalLinkPress?(id: number, url: string): void
    onHelpIconPress?: MouseEventHandler<HTMLDivElement>
}

export const CommunitySponsorBlock = React.forwardRef<
    HTMLDivElement,
    CommunitySponsorBlockProps
>(
    (
        props: CommunitySponsorBlockProps,
        ref: React.ForwardedRef<HTMLDivElement>
    ) => {
        const { classes } = useStyles()
        const { classes: localClasses } = useLocalStyles()

        return (
            <Card
                colors={[colors.grays.BLANC]}
                className={classNames(
                    classes.contentBlock,
                    localClasses.container
                )}
                ref={ref}
            >
                <div className={localClasses.titleContainer}>
                    <img
                        src={CommunitySponsorIcon}
                        className={classes.contentBlockDetailsIcon}
                        alt="Community sponsors icon"
                    />
                    <Typography variant="h2" pl="1rem">
                        Community Sponsors
                    </Typography>
                    <HelpIcon
                        helpText={`HazAdapt works with community sponsors to support the sharing of critical safety information to communities everywhere. Learn more [here](https://www.hazadapt.com/community-sponsors).`}
                        onClick={props.onHelpIconPress}
                    />
                </div>
                <Grid
                    container
                    justifyContent="space-between"
                    rowGap={6}
                    columnGap={{ sm: '1rem', lg: '2rem' }}
                >
                    {props.sponsors.map((s) => (
                        <Grid
                            item
                            xs={12}
                            sm={5.5}
                            lg={3.5}
                            key={`communitySponsor_${s.name}`}
                            onMouseEnter={() =>
                                props.onSponsorAreaHover &&
                                props.onSponsorAreaHover(s.id)
                            }
                            onClick={() =>
                                props.onSponsorAreaClick &&
                                props.onSponsorAreaClick(s.id)
                            }
                        >
                            <CommunitySponsorView
                                {...s}
                                onLinkPress={props.onLinkPress}
                                onSupplementalLinkPress={
                                    props.onSponsorSupplementalLinkPress
                                }
                            />
                        </Grid>
                    ))}
                </Grid>
            </Card>
        )
    }
)

const useLocalStyles = makeStyles()({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '2rem',
        justifyContent: 'center',
    },
})

import {
    getLangObjects,
    LanguageCode,
    LoggedPage,
    NativeLanguageDTO,
    PrepCheckDraftDetails,
    PrepCheckResultOverview,
    PrepCheckStatus,
    sortPrepChecks,
    SupportedHazardFilter,
} from '@hazadapt-git/public-core-base'
import * as clipboard from 'clipboard-polyfill'
import React, { FC } from 'react'
import { IoIosLink } from 'react-icons/io'
import { useLocation, useNavigate } from 'react-router-dom'
import {
    PrepCheckCardBaseProps,
    PrepCheckLandingPageTemplate,
} from '../components'
import { getEnvVars } from '../lib/config'
import { HazardFilter, PageProps } from '../lib/entities'
import {
    getAllPrepChecksThunk,
    getPrepCheckUserStatsThunk,
    saveProfileChangesThunk,
    switchLanguageThunk,
} from '../lib/slices'
import { RootState, useAppDispatch, useAppSelector } from '../lib/store'
import {
    successColor,
    primaryIconSize,
    errorColor,
} from '../lib/styles/universal'
import { logEvent, login, toast, useWindowSizeUp } from '../lib/utils'
import { getAllPrepCheckResults, getAllSubmissions } from '../lib/utils/prep'
import PrepCheckPlaceholderBadge from '../assets/icons/PrepCheckPlaceholderBadge.svg'
import { IoCloudDownloadOutline, IoLanguage } from 'react-icons/io5'

interface PrepCheckLandingPageProps extends PageProps {}

const env = getEnvVars()
export const PrepCheckLandingPage: FC<PrepCheckLandingPageProps> = (
    props: PrepCheckLandingPageProps
) => {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const mediumWindowOrLarger = useWindowSizeUp('md')

    const [prepChecksRetrieved, setPrepChecksRetrieved] =
        React.useState<boolean>(false)
    const [userStatsRetrieved, setUserStatsRetrieved] =
        React.useState<boolean>(false)
    const [resultsRetrieved, setResultRetrieved] =
        React.useState<boolean>(false)
    const [submissionsRetrieved, setSubmissionsRetrieved] =
        React.useState<boolean>(false)
    const [filtersExpanded, setFiltersExpanded] = React.useState<boolean>(false)

    const { languages: supportedLanguages } = useAppSelector(
        (state: RootState) => state.hazards
    )
    const { prepChecks, language, prepCheckStats, user, profileReady } =
        useAppSelector((state: RootState) => ({
            prepChecks: state.prep.prep_checks,
            prepCheckStats: state.prep.stats,
            language: state.profile.language,
            user: state.profile.user,
            profileReady: state.profile.profileReady,
        }))

    const [cards, setCards] = React.useState<PrepCheckCardBaseProps[]>([])
    const [results, setResults] = React.useState<PrepCheckResultOverview[]>([])
    const [submissions, setSubmissions] = React.useState<
        PrepCheckDraftDetails[]
    >([])
    const [filters, setFilters] = React.useState<Set<SupportedHazardFilter>>(
        new Set<SupportedHazardFilter>()
    )
    const [activeFilters, setActiveFilters] = React.useState<
        SupportedHazardFilter[]
    >([])
    const [showLoginWall, setShowLoginWall] = React.useState<boolean>(false)
    const [prepCheckToRedirect, setPrepCheckToRedirect] =
        React.useState<number>()
    const [languageSelectorModalOpen, setLanguageSelectorModalOpen] =
        React.useState<boolean>(false)

    React.useEffect(() => {
        document.title = 'Prep Checks - HazAdapt'
    }, [])

    React.useEffect(() => {
        if (!profileReady) return

        logEvent('OPEN_PAGE', {
            page: LoggedPage.PREP_CHECK_LANDING,
            language,
        })

        dispatch(getAllPrepChecksThunk(language))
            .then(() => setPrepChecksRetrieved(true))
            .catch(console.error)
        dispatch(getPrepCheckUserStatsThunk(language))
            .then(() => setUserStatsRetrieved(true))
            .catch(console.error)

        // Get results and draft submissions
        getAllPrepCheckResults()
            .then(setResults)
            .catch(console.error)
            .finally(() => {
                setResultRetrieved(true)
            })
        getAllSubmissions()
            .then(setSubmissions)
            .catch(console.error)
            .finally(() => {
                setSubmissionsRetrieved(true)
            })
    }, [language, dispatch, profileReady])

    React.useEffect(() => {
        const filters: Set<SupportedHazardFilter> =
            new Set<SupportedHazardFilter>()
        for (const pc of prepChecks) {
            for (const f of pc.filters) {
                filters.add(f)
            }
        }
        setFilters(filters)
    }, [prepChecks])

    React.useEffect(() => {
        const cards: PrepCheckCardBaseProps[] = []
        const sortedPrepChecks = sortPrepChecks(prepChecks, activeFilters)
        for (const pc of sortedPrepChecks) {
            if (
                pc.filters.length > 0 &&
                activeFilters.length > 0 &&
                !pc.filters.some((f) => activeFilters.includes(f))
            ) {
                // Prep check does not include active filters; don't show
                continue
            }

            const draft = submissions.find((d) => d.id === pc.id)
            const result = results.find((r) => r.prep_check_id === pc.id)
            const progress: number = draft?.amountDone ?? 0
            let status: PrepCheckStatus | undefined
            if (draft && draft.amountDone < 1) {
                status = PrepCheckStatus.DRAFT
            } else if (result) {
                status = PrepCheckStatus.SUBMITTED
            }
            cards.push({
                id: pc.id,
                title: pc.title,
                dark_icon: pc.dark_icon?.url ?? pc.dark_icon?.src ?? '',
                filterChips: pc.filters
                    .filter((f) => activeFilters.includes(f))
                    .map((f) => HazardFilter[f]),
                status,
                progressBar: progress,
                score: result?.score,
                badge: result
                    ? result?.badge
                    : {
                          src: PrepCheckPlaceholderBadge,
                          alt: 'Plain dark gray empty badge.',
                      },
            })
        }
        setCards(cards)
    }, [activeFilters, submissions, language, prepChecks, results])

    const onPrepCheckClick = (id: number, done = false) => {
        if (!user) {
            setPrepCheckToRedirect(id)
            setShowLoginWall(true)
            return
        }
        if (done) {
            navigate(`/prep-checks/${id}/results`, {
                state: { allowBack: true },
            })
        } else {
            navigate(`/prep-checks/${id}?from=${location.pathname}`)
        }
    }

    const onJourneyCompletionClick: React.MouseEventHandler = (e) => {
        navigate('/prep-checks')
    }

    const onMyEmergencyItemsClick: React.MouseEventHandler = (e) => {
        navigate('/my-emergency-items')
    }

    const onFilterClick = (filter: SupportedHazardFilter) => {
        const active_filters = [...activeFilters]
        const idx = active_filters.findIndex((f) => f === filter)
        if (idx >= 0) {
            active_filters.splice(idx, 1)
        } else {
            active_filters.unshift(filter)
        }
        setActiveFilters(active_filters)
    }

    const onResetFilters = () => {
        setActiveFilters([])
    }

    const onSharePress = (e: React.MouseEvent, id: number) => {
        e.preventDefault()
        e.stopPropagation()
        const prepCheck = prepChecks.find((pc) => pc.id === id)
        if (!prepCheck) return

        logEvent('SHARE_PREP_CHECK', {
            id,
            name: prepCheck.title,
            page: 'PrepCheckLanding',
        })
        const link = `${env.hazGuideQuickUriBase}/pc/${id}`
        clipboard
            .writeText(link)
            .then(() => {
                toast(
                    'Link copied!',
                    <IoIosLink color={successColor} size={primaryIconSize} />
                )
            })
            .catch(console.error)
    }

    const toggleLoginWall = (state: boolean) => setShowLoginWall(state)

    const onLogin = async () => {
        const redirectUrl = `${env.frontendUriBase}/prep-checks/${
            prepCheckToRedirect || ''
        }`
        await login(redirectUrl)
    }

    const onPrepCheckWelcomeClose = () => {
        if (!user) return
        const tutorials_shown = new Set<string>(user.tutorials_shown)
        tutorials_shown.add('PrepCheckWelcome')
        dispatch(
            saveProfileChangesThunk({
                tutorials_shown: Array.from(tutorials_shown),
            })
        )
    }

    const onLanguageChange = async (lang: LanguageCode) => {
        const langObject: NativeLanguageDTO = getLangObjects([lang])[0]
        setLanguageSelectorModalOpen(false)
        toast(
            `Downloading Prep Checks in ${langObject.title}.`,
            <IoCloudDownloadOutline
                color={successColor}
                size={primaryIconSize}
            />
        )
        try {
            await dispatch(switchLanguageThunk(lang))
            dispatch(getPrepCheckUserStatsThunk(lang))
            await dispatch(getAllPrepChecksThunk(lang))
            toast(
                `Prep Checks have been translated into ${langObject.title}.`,
                <IoLanguage color={successColor} size={primaryIconSize} />
            )
        } catch (err) {
            console.error(err)
            toast(
                `Unable to download Prep Checks in ${langObject.title}.`,
                <IoLanguage color={errorColor} size={primaryIconSize} />
            )
        }
    }

    const onTranslateClick: React.MouseEventHandler = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setLanguageSelectorModalOpen(true)
    }

    const onLanguageSelectorModalClose = () => {
        setLanguageSelectorModalOpen(false)
    }

    const scrollY = React.useRef(0)
    React.useEffect(() => {
        if (mediumWindowOrLarger) {
            if (filtersExpanded) setFiltersExpanded(false)
            return
        }
        const closeFilterViewOnScrollDown = () => {
            if (!filtersExpanded || !mediumWindowOrLarger)
                if (scrollY.current < window.scrollY) {
                    setFiltersExpanded(false)
                }
            scrollY.current = window.scrollY
        }

        window.addEventListener('scroll', closeFilterViewOnScrollDown)
        return () => {
            window.removeEventListener('scroll', closeFilterViewOnScrollDown)
        }
    }, [filtersExpanded, mediumWindowOrLarger])

    return (
        <PrepCheckLandingPageTemplate
            {...prepCheckStats}
            totalPrepChecks={prepCheckStats.numTotal}
            prepChecksCompleted={prepCheckStats.numCompleted}
            activeFilters={activeFilters}
            onChipPress={onFilterClick}
            onChipReset={onResetFilters}
            prepChecks={cards}
            filters={Array.from(filters).map((f) => HazardFilter[f])}
            onPrepCheckClick={onPrepCheckClick}
            onJourneyCompletionClick={onJourneyCompletionClick}
            onMyEmergencyItemsClick={onMyEmergencyItemsClick}
            onSharePress={onSharePress}
            showLoginWall={showLoginWall}
            toggleLoginWall={toggleLoginWall}
            loggedIn={!!user}
            onLogin={onLogin}
            loading={
                props.loading ||
                !prepChecksRetrieved || // prep checks have not been retrieved yet
                (!!user && // user is logged in but their prep check-related info is not all retrieved yet
                    (!userStatsRetrieved ||
                        !resultsRetrieved ||
                        !submissionsRetrieved))
            }
            showPrepCheckWelcome={
                user
                    ? !user.tutorials_shown.includes('PrepCheckWelcome')
                    : false
            }
            onPrepCheckWelcomeClose={onPrepCheckWelcomeClose}
            languages={supportedLanguages}
            selectedLanguage={language}
            onLanguageChange={onLanguageChange}
            onLanguageSelectorModalClose={onLanguageSelectorModalClose}
            languageSelectorModalOpen={languageSelectorModalOpen}
            onTranslateClick={onTranslateClick}
            filtersExpanded={filtersExpanded}
            onFilterViewToggle={setFiltersExpanded}
        />
    )
}
